<template>
  <div>
    <GeneralTableTabs :tabsList="tabsList" @selectTip="handleSelect" />
    <GeneralTable
      ref="GeneralTable"
      tableName="InsuredSchemeTable"
      :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @add-change="handleEdit('add')"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
    >
      <template slot="attachmentResp" slot-scope="{ item }">
        <el-image
          v-if="item.attachmentResp.attachmentUrl"
          :src="item.attachmentResp.attachmentUrl"
          style="width: 48px; height: 48px"
        />
      </template>
      <template slot="isClientDisplay" slot-scope="{ item }">
        <div class="point-main">
          <div :class="['point', item.isClientDisplay ? '' : 'point-no']" />
          <span
            :class="[item.isClientDisplay ? 'point-text' : 'point-text-no']"
            >{{ item.isClientDisplay ? "是" : "否" }}</span
          >
        </div>
      </template>
      <template slot="isAgentSideDisplay" slot-scope="{ item }">
        <div class="point-main">
          <div :class="['point', item.isAgentSideDisplay ? '' : 'point-no']" />
          <span
            :class="[item.isAgentSideDisplay ? 'point-text' : 'point-text-no']"
            >{{ item.isAgentSideDisplay ? "是" : "否" }}</span
          >
        </div>
      </template>
      <template slot="category" slot-scope="{ item }">
        {{ typesInsuranceList[item.category] }}
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button type="text" @click="handleEdit('view', item)"
          >查看详情</el-button
        >
        <el-button type="text" @click="handleEdit('edit', item)"
          >编辑</el-button
        >
        <el-button type="text" @click="handleDel([item])">删除</el-button>
      </template>
    </GeneralTable>
  </div>
</template>

<script>
import {
  getInsuranceSchemeList,
  exportInsuranceScheme,
  deletInsuranceScheme,
  getInsuranceSchemeTypeCount,
} from "@/api/policy";
import GeneralTable from "@/components/GeneralTable/index.vue";
import GeneralTableTabs from "@/components/GeneralTable/GeneralTableTabs.vue";
export default {
  name: "InsuredSchemeManage",
  components: { GeneralTable, GeneralTableTabs },
  data() {
    return {
      curType: "",
      tabsList: [
        { text: "全部", value: "", count: 0 },
        { text: "工程机械", value: 1, count: 0 },
        { text: "车险", value: 2, count: 0 },
        { text: "非设备类财产险", value: 3, count: 0 },
      ],
      dataList: [],
      totalNum: 0,
      typesInsuranceList: { 1: "工程机械", 2: "车险", 3: "非设备类财产险" },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "产品方案名称",
          prop: "insureProgrammeName",
          width: 280,
          isShow: true,
        },
        {
          label: "产品图片",
          prop: "attachmentResp",
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "设备类型",
          prop: "vehicleTypeName",
          width: 280,
          isShow: true,
        },
        {
          label: "险种类型",
          prop: "category",
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "查看部门",
          prop: "viewDeptName",
          width: 280,
          isShow: true,
        },
        {
          label: "所属部门",
          prop: "categoryName",
          width: 280,
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "companyName",
          width: 280,
          isShow: true,
        },
        {
          label: "分支机构",
          prop: "branch",
          width: 280,
          isShow: true,
        },
        {
          label: "是否代理人端展示",
          prop: "isAgentSideDisplay",
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "是否客户端展示",
          prop: "isClientDisplay",
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "客户展示名称",
          prop: "clientDisplayName",
          width: 280,
          isShow: true,
        },
        {
          label: "方案说明",
          prop: "insureProgrammeIllustrate",
          width: 280,
          isShow: true,
        },
        {
          label: "录入员",
          prop: "enteredName",
          width: 280,
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 280,
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 280,
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: true,
        pageSize: 10,
        keyword: "fastBlur",
        searchList: [
          {
            label: "方案名称",
            prop: "insureProgrammeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "所属部门",
            prop: "categoryName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "设备类型",
            prop: "customVehicleTypeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "保险公司",
            prop: "companyName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "分支机构",
            prop: "branch",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "录单员",
            prop: "enteredName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            dateFormat: true,
            prop: ["createTimeBegin", "createTimeEnd"],
          },
          {
            label: "查看部门",
            prop: "viewDeptName",
            formType: "input",
            clearable: true,
            placeholder: "请输入",
          },
        ],
      },
    };
  },
  watch: {
    curType(n) {
      this.getList({ pageNum: 1, pageSize: 10 });
    },
  },
  methods: {
    handleSelect(item) {
      this.curType = item.value;
    },
    getList(data = {}) {
      //console.log(data);
      let datas = { category: this.curType, ...data };
      getInsuranceSchemeTypeCount({
        current: data.pageNum,
        size: data.pageSize,
      }).then((res) => {
        this.tabsList.forEach((item) => {
          res.data.forEach((coun) => {
            if (coun.category == item.value) {
              item.count = coun.count;
            }
          });
        });
      });
      getInsuranceSchemeList(datas, {
        current: data.pageNum,
        size: data.pageSize,
      }).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleEdit(type, item = { id: "", category: "" }) {
      this.$router.push({
        path: "/routerPolicyManagement/insuredManagement/AddInsuredScheme",
        query: { actionType: type, category: item.category, id: item.id },
      });
    },
    handleSearch(data) {
      this.getList(data);
    },
    handleDel(data) {
      if (data.length) {
        this.$confirm(`是否删除`, "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let ids = data.map((item) => {
            return item.id;
          });
          deletInsuranceScheme(ids).then((res) => {
            this.$refs.GeneralTable.getList();
          });
        });
      }
    },
    handleExport(data) {
      let ids = data.map((item) => {
        return item.id;
      });
      if (ids.length) {
        exportInsuranceScheme(ids);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point-main {
  display: flex;
  align-items: center;
  .point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #00bc0d;
  }
  .point-text {
    color: #00bc0d;
  }
  .point-no {
    background-color: #c94242ff;
  }
  .point-text-no {
    color: #c94242ff;
  }
}
</style>
